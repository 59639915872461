import React, {useState} from "react"; 
import Carousel from 'react-bootstrap/Carousel';

const data = [
  {
   image: require('../../img/banana_chips_blackandblue.jpg'), 
   caption:"Banana Chips",
   description:"The crispy delight of our premium banana chips"
  },
  {
    image:require('../../img/banana_chips_black.jpg'), 
    caption:"Banana Chips",
    description:"The crispy delight of our premium banana chips"
   },
   {
    image:require('../../img/banana_chips_blue.jpg'), 
    caption:"Banana Chips",
    description:"The crispy delight of our premium banana chips"
   },
   {
    image:require('../../img/banana_chips_white.jpg'), 
    caption:"Banana Chips",
    description:"The crispy delight of our premium banana chips"
   }  
]


function HomeBanner2() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
       {data.map((slide, i) => {
        return (
          <Carousel.Item>        
        <img
          className="d-block radius-15 p-1 w-90"
          src={slide.image}
          alt="slider image"
        //   height="500"
        />
      </Carousel.Item>
        )
      })}
      
    </Carousel>
  )
}

export default HomeBanner2
