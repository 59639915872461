import React from "react";

const OurStory = () => {
  // const [showDetails, setShowDetails] = useState(false);

  // const toggleDetails = () => {
  //   setShowDetails(!showDetails);
  // };

  return (
    <div className="container my-5">
      {/* <div className="row">
      <div className="col-md-4 text-center swing-in-left-bck">
          <img
            src="img/Logo.png" // Replace with the actual image source
            alt="Royal Brand"
            className="img-fluid"
            width="200"
          />
        </div>
        <div className="col-md-8">
          <h2>Our Story</h2>
          <p>
            Royal Brand, established in 1989, proudly stands as a pioneer in the Indian spice market. From our humble beginnings, we have grown into a prominent manufacturer and distributor of premium blended masalas and pure powders. Our unwavering commitment to authenticity drives us to bring the true essence of Indian cuisine to your plate. We have expanded our offerings to include regional cuisine favorites, such as rice powders, beverages, pickles, and ready-to-eat meals.
          </p>
          <p>
          {showDetails ? (
            <>
            For 23 consecutive years, we've been honored as India's largest spice exporter by the Spices Board of India,
                and our wide-ranging portfolio reaches discerning palates across GCC countries, the US, the UK, Canada, Europe, Singapore, Malaysia, the Maldives, Yemen, Australia, and more.
                <br />
                Our headquarters in Kochi, Kerala, proudly represents Eastern, a subsidiary of the Norwegian conglomerate Orkla. Experience the journey of taste and tradition with us.
              </>
            ) : null}
          </p>
          {!showDetails && (
            <button className="btn custom-btn-primary" onClick={toggleDetails}>
              Read More
            </button>
          )}
        </div>
        
      </div> */}
      <div>
        <div class="intro-text s">
          <div>From a small roaster outlet, <br />
          to a multinational powerhouse with a <br className="none" />
          global footprint - our remarkable success story</div>
        </div>
        <div className="px-3 r sub-p text-center">
          Allow us to introduce you to the world of Royal Brand, where vision
          becomes reality and quality is our constant companion.<br className="none" /> With our roots
          deeply embedded in the economic sphere of Oman, we have evolved into a
          bonfire of excellence<br className="none" /> in the FMCG distribution, wholesale, and retail
          industries.
        </div>
      </div>
    </div>
  );
};

export default OurStory;
