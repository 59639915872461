import React, { useState, useEffect } from "react";

function SignatureProducts() {

    const imageList = [
        { id: 1, src: "img/banana_chips_white.jpg", alt: "Image 1" },
        { id: 2, src: "img/banana_chips_black.jpg", alt: "Image 2" },
        { id: 3, src: "img/banana_chips_blue.jpg", alt: "Image 3" },
        { id: 4, src: "img/banana_chips_blackandblue.jpg", alt: "Image 4" },
        // { id: 5, src: "img/banana_chips_white.jpg", alt: "Image 5" },
      ];
    
      const [startIndex, setStartIndex] = useState(0);
    
      useEffect(() => {
        const interval = setInterval(() => {
          // Calculate the next start index for the next set of images
          const nextStartIndex = (startIndex + getImagesToShow()) % imageList.length;
          setStartIndex(nextStartIndex);
        }, 5000); // 5000 milliseconds = 5 seconds
    
        return () => {
          clearInterval(interval); // Clear the interval when the component unmounts
        };
      }, [startIndex, imageList]);
    
      // Function to determine the number of images to show based on screen size
      const getImagesToShow = () => {
        if (window.innerWidth >= 768) {
          // Show 4 images for screens with width >= 768px (e.g., tablets and desktops)
          return 4;
        } else {
          // Show 2 images for screens with width < 768px (e.g., mobile devices)
          return 2;
        }
      };
    
      // Add a resize event listener to update the number of images when the window size changes
      useEffect(() => {
        const handleResize = () => {
          setStartIndex(0); // Reset the start index when the window size changes
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);

  return (
    <>
    <h2 className="text-center b">SIGNATURE PRODUCTS</h2>
      <div className="sp-container mb-5">
        <div className="sp-image-scroll-container">
          {imageList.slice(startIndex, startIndex + getImagesToShow()).map((image) => (
            <div key={image.id} className="sp-image-card">
              <img
                src={image.src}
                alt={image.alt}
                className="img-fluid"
                width="250"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SignatureProducts
