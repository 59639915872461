import React, { useEffect, useRef, useState } from "react";

function Footer() {
  const footerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const footer = footerRef.current;
      if (!footer) return;

      const windowHeight = window.innerHeight + 100;
      const footerTop = footer.getBoundingClientRect().top;

      if (footerTop < windowHeight ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <footer
      ref={footerRef}
      className={`bg-footer text-center text-lg-start text-white ${isVisible ? 'slide-in-bottom' : 'opacity-0'}`}
    >
      <div className="container p-4 pb-0">
        <section className="">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                Royal Brand
              </h6>
              <p>
              Established in 1989, proudly stands as a pioneer in the Indian spice market. From our humble beginnings, we have grown into a prominent manufacturer and distributor of premium blended masalas and pure powders.
              </p>
            </div>

            <hr class="w-100 clearfix d-md-none" />

            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 class="text-uppercase mb-4 font-weight-bold">Products</h6>
              <p>
                <a class="text-white">Spices & Masalas</a>
              </p>
              <p>
                <a class="text-white">Breakfast</a>
              </p>
              <p>
                <a class="text-white">Pickles & Paste</a>
              </p>
              <p>
                <a class="text-white">Sweets & Desserts</a>
              </p>
              <p>
                <a class="text-white">GCC</a>
              </p>
            </div>

            <hr class="w-100 clearfix d-md-none" />

            {/* <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 class="text-uppercase mb-4 font-weight-bold">Social Media</h6>
              <p>
              <i class="fab fa-twitter m-1"></i>
                <a class="text-white">Twitter</a>
              </p>
              <p>
              <i class="fab fa-facebook m-1"></i>
                <a class="text-white ">Facebook</a>
              </p>
              <p>
              <i class="fab fa-instagram m-1"></i>
                <a class="text-white">Instagram</a>
              </p>
              <p>
              <i class="fab fa-linkedin m-1"></i>
                <a class="text-white">LinkedIn</a>
              </p>
            </div> */}

            <hr class="w-100 clearfix d-md-none" />

            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6>
              <p>
                <i class="fas fa-home mr-3"></i> Thrissur, Kerala, India
              </p>
              <p>
                <i class="fas fa-envelope mr-3"></i> royalbrand@gmail.com
              </p>
              <p>
                <i class="fas fa-phone mr-3"></i> + 01 234 567 88
              </p>
              <p>
                <i class="fas fa-print mr-3"></i> + 01 234 567 89
              </p>
            </div>

          </div>
        </section>

        <hr className="my-3" />

        <section className="p-3 pt-0">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-6 text-center text-md-start">
              <div className="p-3">
                <p className="text-center">&copy; {new Date().getFullYear()} Royal Brand. All rights reserved.</p>
              </div>
            </div>

            <div className="col-md-5 col-lg-6 ml-lg-0 text-center text-md-end">
              <a
                className="btn btn-outline-light btn-floating m-1"
                role="button"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-outline-light btn-floating m-1"
                role="button"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-outline-light btn-floating m-1"
                role="button"
              >
                <i className="fab fa-google"></i>
              </a>
              <a
                className="btn btn-outline-light btn-floating m-1"
                role="button"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
