import React, { useState, useEffect } from "react";
import HomeBanner2 from "./Banners/HomeBanner2";

function OurObjectives() {
  const [CardVisible, setCardVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const Card = document.querySelector('.objective-items-container');
      if (!Card) return;

      const windowHeight = window.innerHeight;
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;

      const CardTop = Card.getBoundingClientRect().top;

      if (isSmallScreen) {
        if (CardTop < windowHeight - 150) {
          setCardVisible(true);
        } else {
          setCardVisible(false);
        }
      } else {
        if (CardTop < windowHeight - 200) {
          setCardVisible(true);
        } else {
          setCardVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const data = [
    {
      num: "01",
      text: "Deliver Unparalleled Service Quality to Customers"
    },
    {
      num: "02",
      text: "Ensure Product Accessibility through Affordability"
    },
    {
      num: "03",
      text: "Cultivate a Supportive Work Environment for Team"
    },
    {
      num: "04",
      text: "Pioneer Industry Leadership in Distribution & FMCG"
    }
  ];

  return (
    <div className="our-objective">
      <div className="title">Our Objectives</div>
      <div className="objective-items-container">
        {data.map((item, index) => (
          <div key={index} className={`objective-item ${CardVisible ? 'slide-in-bottom' : 'opacity-0'}`}>
            <div>
              <div className="center">
                <div className="objective-num b"><div>{item.num}</div></div>
              </div>
              <div className="objective-text r">{item.text}</div>
            </div>
          </div>
        ))}
      </div>
      <HomeBanner2 />
    </div>
  );
}

export default OurObjectives;
