import React from "react";
import { useParams } from "react-router-dom";
import products from "../Data/Products";

function SingleProduct() {
    const { id } = useParams();
    const product = products.find((product) => product.id === parseInt(id));
  
    if (!product) {
      return <div>Product not found</div>;
    }
  
    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-6">
            <img
              src={product.imageUrl}
              className="img-fluid"
              alt={product.name}
            />
          </div>
          <div className="col-lg-6">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <p>Category: {product.category}</p>
          </div>
        </div>
      </div>
    );
  }
  
  export default SingleProduct;