// Dummy product data
const products = [
    {
      id: 1,
      name: "Banana Chips",
      category: "Snacks",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      imageUrl: "img/bc.png",
    },
    {
      id: 2,
      name: "Bombay Mixture",
      category: "Snacks",
      description: "Pellentesque vel justo a dui volutpat bibendum.",
      imageUrl: "img/bm.png",
    },
    {
      id: 3,
      name: "Jack Fruit Chips",
      category: "Pickles",
      description: "Aenean tristique tortor at nulla.",
      imageUrl: "img/jfc.png",
    },
    {
      id: 4,
      name: "Roasted Bitter Gourd",
      category: "Sweets",
      description: "Suspendisse id arcu nec felis.",
      imageUrl: "img/rbg.png",
    },
    {
      id: 5,
      name: "Roasted Peanut",
      category: "Snacks",
      description: "Nunc et pharetra nisl, id elementum tortor.",
      imageUrl: "img/rp.png",
    },
    {
      id: 6,
      name: "Murukku",
      category: "Snacks",
      description: "Vestibulum ac varius felis, id consectetur risus.",
      imageUrl: "img/sm.png",
    },
    {
      id: 7,
      name: "Tapioca Chips",
      category: "Straight Powders",
      description: "Integer ullamcorper dapibus erat id suscipit.",
      imageUrl: "img/tc.png",
    },
    {
      id: 8,
      name: "Tapioca Stick Chips",
      category: "Snacks",
      description: "Quisque vestibulum tristique purus, nec posuere arcu.",
      imageUrl: "img/tsc.png",
    },
    {
      id: 9,
      name: "Thenkuzhal Murukku",
      category: "Snacks",
      description: "Aliquam efficitur hendrerit justo, et tincidunt ligula.",
      imageUrl: "img/tm.png",
    },
    {
      id: 10,
      name: "Mint Murukku",
      category: "Snacks",
      description: "Sed in efficitur est, in suscipit turpis.",
      imageUrl: "img/mm.png",
    },
    {
      id: 11,
      name: "Rice Murukku",
      category: "Snacks",
      description: "Sed in efficitur est, in suscipit turpis.",
      imageUrl: "img/rm.png",
    },
    {
      id: 12,
      name: "Mixture",
      category: "Snacks",
      description: "Sed in efficitur est, in suscipit turpis.",
      imageUrl: "img/Mixture.png",
    },
    {
      id: 13,
      name: "Payyoli Mixture",
      category: "Snacks",
      description: "Sed in efficitur est, in suscipit turpis.",
      imageUrl: "img/pm.png",
    },
    {
      id: 14,
      name: "Potato Chips",
      category: "Snacks",
      description: "Sed in efficitur est, in suscipit turpis.",
      imageUrl: "img/pc.png",
    },
  
    // Add more product entries
  ];

  export default products;