import React, { useState, useEffect } from "react";

function WhatWeDo() {
  const [CardVisible, setCardVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const Card = document.querySelector('.what-we-do-container');
      if (!Card) return;

      const windowHeight = window.innerHeight;
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;

      const CardTop = Card.getBoundingClientRect().top;

      if (isSmallScreen) {
        if (CardTop < windowHeight - 150) {
          setCardVisible(true);
        } else {
          setCardVisible(false);
        }
      } else {
        if (CardTop < windowHeight - 300) {
          setCardVisible(true);
        } else {
          setCardVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const data = [
    {
      img: "https://www.nadahappiness.com/images/b4-1.png",
      title: "Wholesale & Retail",
      description:
        "The perfect ingredients, household essentials, or premium offerings for customers, our Wholesale and Retail FMCG Division is here to serve you. Step into a world where quality meets accessibility, and where your satisfaction is our unwavering mission.",
    },
    {
      img: "https://www.nadahappiness.com/images/b4-2.png",
      title: "Confectionery",
      description:
        "Our confectionery range is a symphony of tastes, catering to a diverse array of preferences-from classic favorites that evoke childhood memories to innovative creations that redefine indulgence",
    },
    {
      img: "https://www.nadahappiness.com/images/b4-3.png",
      title: "Food Processing",
      description:
        "We source the finest ingredients, adhere to stringent quality control, and employ advanced techniques to ensure that every creation that bears the Nada Happiness name meets the highest standards",
    },
    {
      img: "https://www.nadahappiness.com/images/b4-4.png",
      title: "Roastery Division",
      description:
        "From the rich aromas of coffee to the exotic allure of Kawah, the indulgence of dates to the wholesomeness of dry fruits, our diverse range in the roastery division captures the essence of each category, ensuring that every experience is a sensory delight.",
    },
    {
      img: "https://www.nadahappiness.com/images/b4-5.png",
      title: "Consumer Division",
      description:
        "Presenting a curated collection that caters to your modern lifestyle, offering everything from cutting-edge gadgets to everyday necessities. From the latest innovations in branded electronics to the timeless appeal of everyday necessities, our Consumer Division is your portal to a hand-picked selection that upgrades your lifestyle. Each product is a witness to quality, and each experience is a celebration of modern living.",
    },
  ];

  return (
    <div className="text-center p-2">
      <div className="b h1 p-3">What We Do</div>
      <div className="container what-we-do-container">
        {data.map((item, index) => (
          <div key={index} className={`item ${CardVisible ? 'slide-in-bottom' : 'opacity-0'}`}>
            <img className="radius-15 wwd-img p-2" src={item.img} alt="" />
            <div>
              <div className="b p-2">{item.title}</div>
              <div className="r p-2">{item.description}</div>
              <div className="center">
                <div className="wwd-btn r">Explore More <span></span></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhatWeDo;
