import React from 'react';
import ScrollAnimation from './ScrollAnimation';

const TweetMessage = () => {
  return (
    <div className="container-fluid tweet-section">
  <div className="row align-items-center m-5">
    <div className="col-md-6 mb-3 text-center brand-card">
    <ScrollAnimation animationClass="slide-in-left" >
      {/* Centered Image */}
      <img
        src="img/all_snacks.jpg"
        alt="Tweet Image"
        className="img-fluid tweet-image mx-auto rounded"
        width="250"
      />
    </ScrollAnimation>
    </div>
    <div className="col-md-6">
    <ScrollAnimation animationClass="slide-in-right" >
      {/* Tweet Content */}
      <div className="tweet-content">
        <h2 className='font-weight-bold b'>Life is the combination of Magic and Food.</h2>
        <p className='r'>
          We try to create wonders with spices that make new masterpiece recipes bring magic in all its flavors. You have to get the right ingredient and have to get the right mixture for the highest quality recipes.
        </p>
      </div>
      </ScrollAnimation>
    </div>
  </div>
</div>
  );
};

export default TweetMessage;
