import React from "react";
import "./ProductBanner.css";

function ProductBanner() {
  return (
    <div className="banner-container">
      {/* <div className="banner-content">
    <h1>FEATURED PRODUCTS</h1>
    <p>Explore our wide variety of flavors and find your perfect ingredient.</p>
  </div> */}
      <div className="contact-section">
        <div className="first-section">
          <h1 className="b text-center my-3  tracking-in-contract">
            FEATURED PRODUCTS
          </h1>
          <div className="r">
            Explore our wide variety of flavors and find your perfect
            ingredient.
          </div>{" "}
        </div>
      </div>
      <div className="m-4">
        <img
          src="img/Product_Poster.png"
          alt="Banner Image"
          className="img-fluid radius-15"
        />
      </div>
    </div>
  );
}

export default ProductBanner;
