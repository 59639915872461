import React, { useEffect, useState } from 'react';

function LightingText() {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.clientHeight;
            let progress;

            if (window.innerWidth < 800) {
                // Calculations for small screens
                progress = ((scrollPosition - 500 + (windowHeight - 100)) / (documentHeight - 100)) * 2000;
            } else {
                // Calculations for larger screens
                progress = ((scrollPosition - 500  + (windowHeight )) / (documentHeight )) * 1800;
            }

            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const text = "Royal Brand is a pioneer in Oman's retail, wholesale, distribution and trading sectors with a legacy of unwavering excellence. Our portfolio encompasses two distinct companies, each housing six specialized divisions. This intricate framework has led to the establishment of twenty strategic outlets, offering a comprehensive array of over 1,000 products. Supporting these endeavors is our dedicated workforce, comprising 470 skilled professionals, whose collective commitment has fostered an extensive base of satisfied clients. Facilitating smooth logistics and ensuring the timely movement and safe storage of goods is our extensive fleet of carefully maintained vans, cars and trucks. Each passing year has marked a momentous milestone in the evolution of Royal Brand. Central to our narrative are the architects of this triumph – Mr. Shanavas and Mr. Navab, visionaries who have diligently steered Royal Brand towards its current zenith.";

    const renderText = () => {
        const letters = text.split('');
        return letters.map((letter, index) => (
            <span key={index} className={`tosolve-text ${index <= scrollProgress ? 'active' : ''}`}>{letter}</span>
        ));
    };

    return (
        <div className='tosolve-container r px-2'>
            {renderText()}
        </div>
    );
}

export default LightingText;
