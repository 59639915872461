import React from 'react';

function HistoryComponent() {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className='b'>Our Journey</h2>
            <p className='r'>
            Our journey began with two young boys, who started selling homemade roasted cashews. Their humble beginnings involved by carrying a bag full of cashews, walked through the markets and sell their products. That was the lifeline for their daily sustenance. As time passed, their vision expanded, and also their business.they recognised the potential in the market. And later they transitioned into the production of chips and snacks, which marked the inception of exporting to Oman and Bahrain.The journey continued as they ventured into Qatar, establishing their own company that imported and sold their products. This success story extended to the UAE and Oman, with the support of their families. And Our company, Khalid Haddabi Trading too,emerged as a well-regarded name in oman market.
             </p>
          </div>
          {/* Add historical information or images in the other column */}
        </div>
      </div>
    </section>
  );
}

export default HistoryComponent;