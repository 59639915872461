import React from "react";

function Brands() {
  return (
    <div>
      <div className="contact-section">
        <div className="first-section">
          <h1 className="b text-center my-3  tracking-in-contract">Brands</h1>
          <div className="r">
            Carefully Curated Brands Offering Premium Quality
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default Brands;
