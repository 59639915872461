import React, { useEffect, useRef, useState } from "react";

function ScrollAnimation({ children, animationClass, offset = 0 }) {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      if (!element) return;

      const windowHeight = window.innerHeight;
      const elementTop = element.getBoundingClientRect().top;

      if (elementTop < windowHeight - offset) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, [offset]);

  return (
    <div ref={elementRef} className={isVisible ? animationClass : 'opacity-0'}>
      {children}
    </div>
  );
}

export default ScrollAnimation;
