import React, {useState} from "react"; 
import Carousel from 'react-bootstrap/Carousel';

const data = [
  {
   image: require('../img/banana_chips_blackandblue.jpg'), 
   caption:"Banana Chips",
   description:"The crispy delight of our premium banana chips"
  },
  {
    image:require('../img/banana_chips_black.jpg'), 
    caption:"Banana Chips",
    description:"The crispy delight of our premium banana chips"
   },
   {
    image:require('../img/banana_chips_blue.jpg'), 
    caption:"Banana Chips",
    description:"The crispy delight of our premium banana chips"
   },
   {
    image:require('../img/banana_chips_white.jpg'), 
    caption:"Banana Chips",
    description:"The crispy delight of our premium banana chips"
   }  
]

function HomeCarousel() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="p-2">
    <Carousel activeIndex={index} onSelect={handleSelect}>
       {data.map((slide, i) => {
        return (
          <Carousel.Item>    
        <img
          className="d-block w-100 radius-15"
          src={slide.image}
          alt="slider image"
        //   height="500"
        />
        <Carousel.Caption>
          <h3 className="tracking-in-contract">{slide.caption}</h3>
          <p>{slide.description}</p>
        </Carousel.Caption>
      </Carousel.Item>
        )
      })}
      
    </Carousel>
    </div>
  );
}
export default HomeCarousel;