import React, { useEffect } from "react";
import ProductBanner from "../Components/ProductPage/ProductBanner";
import ProductListing from "../Components/ProductPage/ProductListing";
import Footer from '../Components/Footer'

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the Company component is mounted
  }, []);

  return (
    <div className="justify-content-center align-items-center">
      {/* <div>
        <h1 className="text-center py-3 tracking-in-contract-bck">OUR PRODUCTS</h1>
      </div> */}
      <ProductBanner/>
      <ProductListing/>
      <Footer/>
    </div>
  );
}

export default Products;
