import React, { useState } from "react";
import products from "../../Data/Products";
// import { Link } from "react-router-dom";
import './ProductBanner.css'



function ProductListing() {
  const [category, setCategory] = useState("All"); // Default category is 'All'
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 8; // Number of products per page

  // Get a list of unique categories from the products
  const uniqueCategories = [...new Set(products.map((product) => product.category))];

  // Filter products based on category and search term
  const filteredProducts = products.filter((product) => {
    return (
      (category === "All" || product.category === category) &&
      (searchTerm === "" ||
        product.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container mt-4">
      <div className="text-center">
        {/* Category Selection as Buttons */}
        <div className="category-btn">
          <button
            type="button"
            className={` bttn mx-2 ${category === "All" ? "selbtn" : ""}`}
            onClick={() => setCategory("All")}
          >
            All Categories
          </button>
          {uniqueCategories.map((uniqueCategory) => (
            <button
              key={uniqueCategory}
              type="button"
              className={`bttn mx-2 mt-2 ${category === uniqueCategory ? "selbtn" : ""}`}
              onClick={() => setCategory(uniqueCategory)}
            >
              {uniqueCategory}
            </button>
          ))}
        </div>
        {/* Search Bar */}
        <input
          type="text"
          className="form-control mt-3"
          placeholder="Search products"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="row">
        {products.map((product) => (
          <div key={product.id} className="col-lg-3 col-md-6 col-sm-6 my-3">
            {/* <Link to={{ pathname: `/product/${product.id}` }}> */}
              <div className="card swing-in-left-bck prd-list">
                <img
                  src={product.imageUrl}
                  className="card-img-top img-fluid"
                  alt={product.name}
                  style={{ maxWidth: "100%" }}
                />
                <div className="card-body">
                  <h5 className="card-title">{product.name}</h5>
                </div>
              </div>
            {/* </Link> */}
          </div>
        ))}
      </div>

      {/* Pagination */}
      <nav>
        <ul className="pagination justify-content-center">
          {Array(Math.ceil(filteredProducts.length / productsPerPage))
            .fill()
            .map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  index + 1 === currentPage ? "active" : ""
                }`}
              >
                <button
                  onClick={() => paginate(index + 1)}
                  className="page-link"
                >
                  {index + 1}
                </button>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
}

export default ProductListing;
