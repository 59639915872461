import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import "./Style/style.css";
import "./Style/animations.css";
import Company from "./Pages/Company";
import Contact from "./Pages/Contact";
import Products from "./Pages/Products";
import SingleProduct from "./Pages/SingleProduct";
import Sidebar from "./Components/Sidebar/Sidebar";
import Loader from "./Components/Loader/Loader";
import Brands from "./Pages/Brands";


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay (you would replace this with your actual data loading process)
    const delay = setTimeout(() => {
      setLoading(false); // Set loading state to false after the simulated delay
    }, 1500); // Simulated delay of 2 seconds

    // Cleanup function to clear timeout if component unmounts before the delay completes
    return () => clearTimeout(delay);
  }, []);
  return (
    <Router>
      {loading ? (
          // Render loading indicator while loading is true
          <Loader />
        ) : (
      <>
        <Sidebar />
        <div className="overflow-hidden custom-scrollbar top-m">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<Company />} />
            <Route path="/products" element={<Products />} />
            {/* <Route path="/product/:id" element={<SingleProduct />} /> */}
            <Route path="/brands" element={<Brands />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </>
        )}
    </Router>
  );
}

export default App;
