import React, { useState, useEffect } from "react";

function WhyChooseUs() {
  const [boxVisible, setBoxVisible] = useState({
    box1: false,
    box2: false,
    box3: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      const boxes = document.querySelectorAll('.wcu-box');
      const windowHeight = window.innerHeight;
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;

      boxes.forEach((box, index) => {
        const boxTop = box.getBoundingClientRect().top;
        if (isSmallScreen) {
          if (boxTop < windowHeight - 150) {
            setBoxVisible(prevState => ({ ...prevState, [`box${index + 1}`]: true }));
          }
        } else {
          if (boxTop < windowHeight ) {
            setBoxVisible(prevState => ({ ...prevState, [`box${index + 1}`]: true }));
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="my-2">
      <div className="wcu">
        <div className="a quote">
          Our belief - "The right business ethics empower attaining a successful
          business empire."
        </div>
        <div>
          <div className="b p-1 text-center h1">Why Choose Us</div>
          <div className="r p-1 wcu-p">
            Choosing Royal Brand as your preferred partner is not just a
            decision; it's an investment in excellence, value, and trust. We
            understand the industry inside out, and our insights drive our
            commitment to perfection. Here's why Royal Brand stands head and
            shoulders above the rest:
          </div>
          <div className="wcu-box-section">
          <div className={`d-flex wcu-box ${boxVisible.box1 ? 'slide-in-bottom' : 'opacity-0'}`}>
            <div className="img-div">
              <img
                className=""
                src="https://www.nadahappiness.com/images/order.png"
                alt=""
              />
            </div>
            <div className="center p-2">
              <div>
                <div className="p-1 b">Quality Products</div>
                <div className="r">
                  From sourcing raw materials to delivering finished products,
                  we maintain rigorous quality control measures to ensure top
                  quality for every product
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex wcu-box ${boxVisible.box2 ? 'slide-in-bottom' : 'opacity-0'}`}>
            <div className="img-div">
              <img
                className=""
                src="https://www.nadahappiness.com/images/happiness.png"
                alt=""
              />
            </div>
            <div className="center p-2">
              <div>
                <div className="p-1 b">Friendly Staff</div>
                <div className="r">
                  Our friendly and professional staff creates an environment
                  where you feel valued, heard, and understood—our dedication to
                  customer satisfaction
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex wcu-box ${boxVisible.box3 ? 'slide-in-bottom' : 'opacity-0'}`}>
            <div className="img-div">
              <img
                className=""
                src="https://www.nadahappiness.com/images/best-product.png"
                alt=""
              />
            </div>
            <div className="center p-2">
              <div>
                <div className="p-1 b">Best Price</div>
                <div className="r">
                  We believe quality should be accessible to all. You not only
                  receive top-notch products but also do so without putting a
                  strain on your budget.
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div className="center wcu-b-p a">
        <div className="p">
          We guarantee satisfaction through value-added products and uphold
          trust with suppliers as a sincere partner
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUs;
