import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const BrandInfo = () => {
  const [CardVisible, setCardVisible] = useState(false);
  // const [rightCardVisible, setRightCardVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const leftCard = document.querySelector('.left-card');
      // const rightCard = document.querySelector('.right-card');
      if (!leftCard ) return;

      const windowHeight = window.innerHeight;
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;

      const leftCardTop = leftCard.getBoundingClientRect().top;
      // const rightCardTop = rightCard.getBoundingClientRect().top;

      if (isSmallScreen) {
        // Adjust the threshold for small screens
        if (leftCardTop < windowHeight - 200) {
          setCardVisible(true);
        }
        else {
          setCardVisible(false);
        }

      } else {

      if (leftCardTop < windowHeight - 150 ) {
        setCardVisible(true);
      }
      else {
        setCardVisible(false);
      }
    }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="container my-5">
      <div className="row">
        {/* Card 1 */}
        <div className="col-md-6 mb-4  left-card">
          <div className={`brand-info-card ${CardVisible ? 'slide-in-left' : 'opacity-0'}`}>
            <div className="card-body d-flex justify-content-center align-items-center my-3">
              <div className="mr-3">
                {/* <FontAwesomeIcon icon={faGlobe} className="icon-white m-3" /> */}
                <img src={'img/World-map.png'} className="w-100 px-3" alt="" />
              </div>
              <div>
                <h4 className="card-title  b">WE'RE WORLDWIDE</h4>
              </div>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-6 mb-4 right-card">
          <div className={`brand-info-card ${CardVisible ? 'slide-in-right' : 'opacity-0'}`}>
            <div className="card-body d-flex justify-content-center align-items-center my-3">
              <div className="mr-3">
                {/* <FontAwesomeIcon icon={faThumbsUp} className="icon-white m-3" /> */}
                <img src={'img/good-quality.png'} className="w-100 px-3" alt="" />
              </div>
              <div>
                <h4 className="card-title b">QUALITY PROCESS</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandInfo;
