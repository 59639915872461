import React, { useEffect } from "react";
import Footer from "../Components/Footer";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className="contact-section">
        <div className="first-section">
          <div className="b h2 p-2 tracking-in-contract">Contact US</div>
          <div className="r p-2 h4">We Are Waiting To Hear From You</div>
        </div>
        <div className="second-section">
          <div className="sub-1 slide-in-left">
            <div className="b py-2">Queries? Do Reach Us Out!</div>
            <div className="r py-2">
              We are always available to address your concerns through the
              following means. Don’t hesitate!
            </div>
            <div className="r sub-box pt-4">
              <img
                src="https://www.nadahappiness.com/images/contact-pin.png"
                alt=""
              />
              <div className="sub-details">
                <div className=" b">Global Exporters</div>
                P.O: 680691, Kathikkod Road, Koolimuttam, Kerala, India.
              </div>
            </div>
            <div className="sub-boxs">
              <div className="r sub-box">
                <img
                  src="https://www.nadahappiness.com/images/contact-email.png"
                  alt=""
                />
                <div className="sub-details">
                  <div className="b">Mail us</div>
                  royalbrandfoods@yahoo.com
                </div>
              </div>
              <div className="r sub-box">
                <img
                  src="https://www.nadahappiness.com/images/contact-call.png"
                  alt=""
                />
                <div className="sub-details">
                  <div className="b">call us</div>
                  +987654321
                </div>
              </div>
            </div>
          </div>
          <div className="sub-2 slide-in-right">
            <div className="r">
              <img
                src="https://www.nadahappiness.com/images/email-marketing.png"
                alt=""
              />
              Let’s make it easy for you! Send us your message. We will contact
              you soon.
            </div>
            <div className="form">
              <div className="row">
                <input type="text" placeholder="Full Name" />
                <input type="text" placeholder="Email" />
              </div>
              <div className="row">
              <input type="text" placeholder="Contact Number" />
              <input type="text" placeholder="Subject" />
              </div>
              <textarea placeholder="Message" />
              <div className="wwd-btn r">Send Message <span></span></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
