import React, { useEffect } from "react";
import HomeCarousel from '../Components/HomeCarousel'
import OurStory from '../Components/OurStory'
import BrandInfo from '../Components/BrandInfo'
import SignatureProducts from '../Components/SignatureProducts'
import Footer from '../Components/Footer'
import TweetMessage from '../Components/TweetMessage '
import OurObjectives from "../Components/OurObjectives";
import WhatWeDo from "../Components/WhatWeDo";
import WhyChooseUs from "../Components/WhyChooseUs";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the Company component is mounted
  }, []);

  return (
    <>
      <HomeCarousel/>
      <OurStory/>
      <BrandInfo/>
      <OurObjectives />
      <WhatWeDo />
      <WhyChooseUs />
      <SignatureProducts/>
      <TweetMessage/>
      <Footer/>
    </>
  )
}

export default Home
