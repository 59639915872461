import React, { useEffect } from "react";
import FuturePlansComponent from "../Components/AboutCompany/FuturePlansComponent";
import BannerComponent from "../Components/AboutCompany/BannerComponent";
import HistoryComponent from "../Components/AboutCompany/HistoryComponent";
import Footer from "../Components/Footer";
import LightingText from "../Components/LightingText";
import ScrollAnimation from "../Components/ScrollAnimation";

function Company() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the Company component is mounted
  }, []);

  return (
    <>
      <div className="">
        <div className="contact-section">
          <div className="first-section">
            <h1 className="b text-center my-3  tracking-in-contract">
              About Us{" "}
            </h1>
            <div className="r">A Single Stop Destination For Happiness</div>{" "}
          </div>
        </div>

        <div className="container">
          <div className="row text-center justify-content-center align-items-center">
            <div className="col-lg-4 col-md-6 col-12">
              <img
                className="img-fluid slide-in-left mx-2"
                src="img/Logo.png"
                alt="Royal Brand"
              />
            </div>
            <div className="col-lg-8 col-md-6 col-12 h3">
              <ScrollAnimation animationClass="slide-in-right" >
                <div className="b ">
                  About Royal Brand
                  <div className="r">
                    From Humble Beginings To A Successful Multinational Company
                    - A Tale of Hardwork & Resilience
                  </div>
                </div>
                </ScrollAnimation>
            </div>
          </div>
        </div>

        <LightingText />

        <div className="container my-5 slide-in-left">
          <div className="row text-center justify-content-center align-items-center">
            <div className="col-lg-6 col-md-6 col-12">
              <ScrollAnimation animationClass="slide-in-right" >
                <div className="s h3 my-4">
                  A persistent passion to offer products and services of
                  unmatched quality every day!
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <img
                className="img-fluid radius-15 slide-in-right my-2"
                src="https://www.nadahappiness.com/images/quote-img.png"
                alt="Royal Brand"
              />
            </div>
          </div>
        </div>

        <div className="row">
         
          {/* <div className="col-lg-6 slide-in-left p-4">
            <BannerComponent />
          </div>
          <div className="col-lg-6 slide-in-right  ">
            <FuturePlansComponent />
          </div> */}
          {/* Single column for smaller screens */}
          <div className="col-12">
          <ScrollAnimation animationClass="slide-in-bottom" >
            <HistoryComponent />
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Company;
